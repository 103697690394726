@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');

html {
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
}

.App {
  height: 100vh;
  width: 100vw;
}

h1 {
  font-size: 4rem;
  z-index: 1;
  color: white;
  margin: 0 auto;
}

.hero-text {
  font-size: 5em;
  filter: drop-shadow(0 0 0.25rem black);
}

.hero-subtext {
  font-size: 2em;
  filter: drop-shadow(0 0 0.25rem black);
  color: #fff;
  padding-left: .3rem;
}

.intro {
  z-index: 1;
  color: white;
  font-size: 2rem;
}

.elevated {
  background: #283344;
}

.MuiButtonBase-root {
  color: white;
}

.parallax-effect {
  transform: perspective(1000px);
  background-image: url('/src/components/portfolio-images/planet.svg');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  transform-style: preserve-3d;
  position: relative;
  animation: fadeIn 2s;
}

.inner-element {
  transform: translateZ(40px) scale(0.9);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .typewriter {
    font-size: 2rem;
    color: white;
    z-index: 1;
    margin: 0 auto;
  }

  .about-heading {
    margin-bottom: 3rem;
  }

  .hero-text {
    font-size: 5em;
    filter: drop-shadow(0 0 0.25rem black);
  }
  
  .hero-subtext {
    font-size: 2em;
    filter: drop-shadow(0 0 0.25rem black);
    color: #fff;
    padding-left: .3rem;
  }
}

@media only screen and (max-width: 768px) {
  .typewriter {
    font-size: .55em;
    color: white;
    z-index: 1;
    margin: 0 auto;
  }

  .about-heading {
    margin-bottom: .5rem;
    margin-top: 1rem;
  }

  .hero-text {
    padding-top: .5em;
    font-size: 3em;
    filter: drop-shadow(0 0 0.25rem black);
  }
  
  .hero-subtext {
    font-size: 1.5em;
    filter: drop-shadow(0 0 0.25rem black);
    color: #fff;
    padding-left: .3rem;
    padding-bottom: 0rem;
  }
}
